function envLocalConfig() {
  var IS_PRODUCTION = false;
  var BASE_URL = "http://localhost:4300/api/";
  var VYPER_REFURBISH_PORTAL_URL = "https://stgrefurbish.vyper.cloud/";
  var IMG_URL = "https://edhiryalog.blob.core.windows.net/";
  var VYPER_EDI_URL = "http://edi.vyper.cloud/";
  var VYPER_BRAND_PORTAL_URL = "http://brandportal.stg.vyper.cloud/";
  var PURCHASE_INVOICE_SETTLEMENT_NOTIFY_EMAILS = ["ravisssndu@edhirya.com"];

  var KEYCLOAK_URL = "https://auth.vyper.cloud/auth";
  var KEYCLOAK_REALM = "vyper-test";
  var KEYCLOAK_CLIENT = "web-client";
  var KEYCLOAK_SECRET = "8cdHmLFdjH7o11kuiH4iM83B8HO0JOeG";

  var KEYCLOAK_ON_LOAD = "check-sso";
  var KEYCLOAK_CHECK_LOGIN_FRAME = false;

  var KEYCLOAK_ENABLE_BEARER_INTERCEPTOR = false;
  var KEYCLOAK_BEARER_EXCLUDED_URLS = ["/assets", "/clients/public"]

  this.isProduction = function () {
    return IS_PRODUCTION;
  }

  this.getBaseUrl = function () {
    return BASE_URL;
  }

  this.getImageUrl = function () {
    return IMG_URL;
  }

  this.getVyperEdiUrl = function () {
    return VYPER_EDI_URL;
  }

  this.getVyperBrandPortalUrl = function () {
    return VYPER_BRAND_PORTAL_URL;
  }

  this.getPurchaseInvoiceSettlementNotifyEmails = function () {
    return PURCHASE_INVOICE_SETTLEMENT_NOTIFY_EMAILS;
  }

  this.getKeycloakUrl = function () {
    return KEYCLOAK_URL;
  }

  this.getKeycloakRealm = function () {
    return KEYCLOAK_REALM;
  }

  this.getKeycloakClient = function () {
    return KEYCLOAK_CLIENT;
  }

  this.getKeycloakSecret = function () {
    return KEYCLOAK_SECRET;
  }

  this.getKeycloakOnLoad = function () {
    return KEYCLOAK_ON_LOAD;
  }

  this.isKeycloakCheckLoginFrame = function () {
    return KEYCLOAK_CHECK_LOGIN_FRAME;
  }

  this.isKeycloakEnableBearerInterceptor = function () {
    return KEYCLOAK_ENABLE_BEARER_INTERCEPTOR;
  }

  this.getKeycloakExcludedUrls = function () {
    return KEYCLOAK_BEARER_EXCLUDED_URLS;
  }
  this.getVyperRefurbishPortalUrl = function () {
    return VYPER_REFURBISH_PORTAL_URL;
  }
}
